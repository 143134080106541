<template>
  <div id="timeline-step-flight-slot-status">
    <b-overlay :show="tripStepLoading">
      <b-button
        v-if="stepEvent && stepEvent.slotStatus"
        id="timeline-step-flight-slot-status-btn"
        v-ripple.400
        :variant="`${resolveSlotStatusVariant(stepEvent.slotStatus)}`"
        size="sm"
        @click.prevent="editSlot"
      >
        <span class="align-middle"> {{
          $t('trip.event.slot.title')
        }} : {{ stepEvent.slotStatus | enumTranslate('slot_status') }} </span>
      </b-button>
    </b-overlay>

    <b-sidebar id="timeline-step-flight-slot-status-sidebar" :visible="slotEdition" @change="slotEdition = $event">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ $t('trip.event.slot.title') }} -
            {{ stepType === 'departure' ? $t('trip.departure') : $t('trip.arrival') }}</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <div class="p-2">
          <div class="d-flex mb-1 justify-content-between align-items-center">
            <!--CALENDAR-->
            <b-card-sub-title>
              <span>{{ $t('trip.event.slot.ppr_date') }}:</span>
              <span> {{ $moment.utc(stepEvent.pprRequestDate).format('ddd, MMM Do YYYY HH:mm') }} {{
                $t('common.utc')
              }}</span>
              <br>
              <span>{{ $t('common.airport') }}:</span>
              <span v-if="stepEvent.airport"> {{ stepEvent.airport.name }} - {{ stepEvent.airport.icaoCode }}</span>
            </b-card-sub-title>

            <TimelineStepFlightPpr
              v-if="$can('TRIP_SLOT_MANAGEMENT_VIEW') && stepEvent.allowedToSeePpr"
              :step-event="stepEvent"
              :step-trip="stepTrip"
              :step-type="stepType"
            />
          </div>
          <b-row v-if="(stepEvent.slotStatus === 'not_assigned' || stepEvent.slotStatus === 'submitted') && isBuyer">
            <b-col class="mb-50" cols="12">
              <span class="font-weight-bold mr-25">{{
                `${$t('trip.validate_airport.submit_slot.slot_number')} : `
              }} </span>
              <span v-if="stepEvent.slotNumber"> {{ stepEvent.slotNumber }} </span>
            </b-col>
            <b-col class="mb-50" cols="12">
              <span class="font-weight-bold mr-25">{{ `${$t('common.date')} : ` }} </span>
              <span v-if="stepEvent.slotStartDate">
                {{ $moment.utc(stepEvent.slotStartDate).lang($i18n.locale).format('ddd, MMM Do YYYY') }}
              </span>
            </b-col>
            <b-col class="mb-50" cols="12">
              <span class="font-weight-bold mr-25">{{ `${$t('common.start_at')} : ` }} </span>
              <span v-if="stepEvent.slotStartDate">
                {{ $moment.utc(stepEvent.slotStartDate).lang($i18n.locale).format('HH:mm') }} {{ $t('common.utc') }}
              </span>
            </b-col>
            <b-col class="mb-50" cols="12">
              <span class="font-weight-bold mr-25">{{ `${$t('common.end_at')} : ` }} </span>
              <span v-if="stepEvent.slotStartDate">
                {{ $moment.utc(stepEvent.slotEndDate).lang($i18n.locale).format('HH:mm') }} {{ $t('common.utc') }}
              </span>
            </b-col>
            <b-col class="mb-50" cols="12">
              <span class="font-weight-bold mb-25">{{ `${$t('common.comment')} : ` }}</span>
              <p v-if="stepEvent.slotComment" class="card-text">
                {{ stepEvent.slotComment }}
              </p>
            </b-col>

            <b-col class="mb-50" cols="12">
              <span class="font-weight-bold mb-25">{{ `${$t('common.files')} : ` }}</span>
              <b-container v-if="stepEvent.slotFileUrl && stepEvent.slotFilePath" fluid>
                <b-row align-h="between" class="mb-2 img-group hide-scroll">
                  <b-col class="mx-n1">
                    <figure class="file-figure m-25">
                      <b-img
                        :src="stepEvent.slotFileUrl"
                        alt="slotFileUrl"
                        height="100px"
                        rounded
                        @click="openImageUrl(stepEvent.slotFileUrl)"
                        @error="imageLoadOnError"
                      />
                      <figcaption class="font-italic font-weight-light font-small-2 mt-50">{{
                        stepEvent.slotFilePath
                      }}
                      </figcaption>
                    </figure>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <div v-if="stepEvent.slotStatus === 'not_assigned' && $can('TRIP_EDIT')">
            <b-button
              id="timeline-step-flight-slot-status-action-accept"
              block
              class="w-100"
              type="button"
              variant="success"
              @click.prevent="acceptSlot(), hide()"
            >
              <feather-icon class="align-middle" icon="CheckIcon" size="20" />
              <span class="ml-25 align-middle"> {{ $t('action.accept') }} </span>
            </b-button>
            <b-button
              id="timeline-step-flight-slot-status-action-edit"
              :to="{
                name: 'airport-map',
                params: {
                  trip_id: $route.params.trip_id,
                  event_id: stepTrip.id,
                  leg_id: stepTrip.flightDeparture.id,
                },
              }"
              block
              class="w-100"
              type="button"
              variant="danger"
            >
              <feather-icon class="align-middle" icon="MinusCircleIcon" size="20" />
              <span class="ml-25 align-middle"> {{ $t('action.decline') }} </span>
            </b-button>
            <p class="mt-2">{{ $t('common.note') }} {{ $t('trip.event.slot.decline_slot_text') }}</p>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import UIHelper from '@/helpers/ui'

import {
  deleteTripStepDeleteRequest,
  patchTripStepSlotValidateRequest,
} from '@/request/globalApi/requests/tripStepRequests'

export default {
  name: 'TimelineStepFlightSlotStatus',

  components: {
    TimelineStepFlightPpr: () => import('./TimelineStepFlightPpr.vue'),
  },

  mixins: [UIHelper],

  props: {
    stepEvent: {
      type: Object,
      default: () => ({}),
    },

    stepTrip: {
      type: Object,
      default: () => ({}),
    },

    tripStepLoading: {
      type: Boolean,
      default: false,
    },

    isBuyer: {
      type: Boolean,
      default: false,
    },

    stepType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      slotEdition: false,
      pprLoading: false,
      ppr: {},
    }
  },

  methods: {
    acceptSlot() {
      this.$swal({
        title: this.$t('common.are_you_sure'),
        text: this.$t('trip.event.slot.confirm_accept_slot_text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          patchTripStepSlotValidateRequest(this.stepTrip.id, this.stepEvent)
            .then(() => {
              this.slotEdition = false
            })
            .then(() => {
              this.$emit('refetch-data')
            })
        }
      })
    },

    editSlot() {
      if (this.isBuyer) {
        this.slotEdition = true
      }
    },

    declineSlot() {
      deleteTripStepDeleteRequest(this.stepTrip.id, '', false, this.$t('trip.event.slot.decline_slot_text'))
        .then(() => {
          this.slotEdition = false
        })
        .then(() => {
          this.$emit('refetch-data')
        })
    },

    imageLoadOnError(e) {
      e.target.src = require('@/assets/images/icons/file-icons/doc2.png')
    },

    openImageUrl(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
